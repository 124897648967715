<template>
    <ion-page>
        <ion-content :fullscreen="true" class="ion-no-padding">
            <ion-item lines="full" mode="md" v-for="group of groups" :key="group.id" @click="viewImages(group)">
                <ion-label>
                    <h2>{{ group.name }}</h2>
                    <p>{{ group.published ? language.published : language.notPublished }}</p>
                </ion-label>
                <div class="icon-container" slot="end" @click.stop="presentOptions(group)"><ion-icon :icon="ellipsisVertical" class="edit-forward"></ion-icon></div>
            </ion-item>
            <ion-modal
                :is-open="isEditGroupOpen"
                backdropDismiss="false"
                mode="md"
                css-class="custom-modal"
                @ionModalDidDismiss="setOpen(false)">
                <div class="modal-container">
                    <ion-header>
                        <ion-toolbar>
                            <ion-title>{{ modalTitle }}</ion-title>
                            <ion-buttons slot="end">
                                <ion-button @click="setOpen(false)"><ion-icon :icon="closeOutline"/></ion-button>
                            </ion-buttons>
                        </ion-toolbar>
                    </ion-header>
                    <ion-content class="ion-no-padding">
                        <ion-item lines="full" mode="md">
                            <ion-label position="stacked">{{ modalLabel }}</ion-label>
                            <ion-input autocomplete="off" :placeholder="language.name" v-model="editingGroup.name" />
                        </ion-item>
                    </ion-content>
                    <FixedFooter :buttons="[{
                            class: 'custom-button button-clear',
                            icon: true,
                            iconPos: 'start',
                            handler: () => setOpen(false),
                            text: language.cancel
                        },
                        {
                            class: 'custom-button button-primary',
                            icon: false,
                            iconPos: '',
                            handler: () => saveGroup(),
                            text: modalTitle.includes('New') ? language.save : language.saveEdits
                        }]"/>
                </div>
            </ion-modal>
        </ion-content>
        <FixedFooter :buttons="footerButtons"/>
    </ion-page>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from "vue";
import {GET, PUT} from "@/services/HTTP";
import { ImageGroup } from "@/models/ImageGroupModel";
import { execute } from "@/mixins/LoadingMixin";
import { useRoute } from "vue-router";
import {
    IonContent,
    IonPage,
    actionSheetController,
    IonModal,
    IonLabel,
    IonInput,
    IonItem,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon
} from "@ionic/vue";
import { ellipsisVertical, createOutline, pushOutline, closeOutline } from "ionicons/icons";
import FixedFooter from '@/components/FixedFooter.vue'
import router from "@/router";
import {presentValidationAlert} from "@/services/Notify";
import {IMAGE_GROUP_TYPES, ImageGroupId} from "@/models/ImageGroupTypes";
import { EMPTY_GUID } from "@/utils/GuidUtil";
import {useStore} from "vuex";
import {getTranslatedNumber} from "@/utils/NumberTranslate";

export default defineComponent({
    name: "ImageGroupDetails",
    components: {
        IonContent,
        IonPage,
        FixedFooter,
        IonModal,
        IonItem,
        IonInput,
        IonLabel,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonButtons,
        IonButton,
        IonIcon
    },
    async ionViewWillEnter() {
        execute(async () => {
            this.groups = await GET('/imageGroups/' + this.imageGroupTypeId);
            await this.store.dispatch('app/setPageTitle', IMAGE_GROUP_TYPES.get(this.imageGroupTypeId));
        }, this.language.errors.gettingGroup);
    },
    setup() {
        const store = useStore();
        const language = computed(() => store.getters['app/language']);
        const route = useRoute();
        const imageGroupTypeId = computed(() => route.params.id as string);
        const isImage = computed(() => imageGroupTypeId.value === ImageGroupId.JourneyImages);
        const modalTitle = ref('');
        const modalLabel = computed(() => isImage.value ? language.value.imageGroupName : language.value.iconGroupName);
        const isEditGroupOpen = ref(false);
        const setOpen = (state: boolean) => isEditGroupOpen.value = state;
        const groups = ref([] as ImageGroup[]);
        const editingGroup = ref({} as ImageGroup);
        
        
        const viewImages = (group: ImageGroup) => {
            router.push({ name: 'images', params: {imageGroupTypeId: imageGroupTypeId.value, imageGroupId: group.id}});
        }
        
        const saveGroup = () => {
            if (!editingGroup.value.name.trim()) {
                presentValidationAlert(language.value.validation.enterName);
            } else
                execute(async () => {
                    editingGroup.value.imageGroupTypeId = imageGroupTypeId.value as string;
                    await PUT('/imageGroups', editingGroup.value);
                    setOpen(false);
                    groups.value = await GET('/imageGroups/' + imageGroupTypeId.value);
                }, language.value.errors.savingGroup);
        }
        
        const presentOptions = async (group: ImageGroup) => {
            const actionSheet = await actionSheetController
                .create({
                    header: group.name,
                    cssClass: 'custom-action-sheet',
                    buttons: [
                        {
                            text: language.value.edit,
                            role: 'destructive',
                            icon: createOutline,
                            handler: () => {
                                editingGroup.value = JSON.parse(JSON.stringify(group));
                                modalTitle.value = isImage.value ? language.value.editImageGroup : language.value.editIconGroup;
                                setOpen(true);
                            },
                        },
                        {
                            text: group.published ? language.value.unpublish : language.value.publish,
                            role: 'destructive',
                            icon: pushOutline,
                            handler: () => {
                                if (group.images.length === 0 && !group.published) {
                                    presentValidationAlert(isImage.value ? language.value.validation.mustHaveImages : language.value.validation.mustHaveIcons);
                                } else {
                                    execute(async () => {
                                        group.published = !group.published;
                                        await PUT('/imageGroups', group);
                                    }, isImage.value ? language.value.errors.updatingImageGroup : language.value.errors.updatingIconGroup);
                                }
                            },
                        }
                    ],
                    mode: "md"
                });
            await actionSheet.present();
        }
        
        return {
            groups,
            imageGroupTypeId,
            ellipsisVertical,
            viewImages,
            language,
            store,
            footerButtons: computed(() => {
                return ([{
                    class: 'custom-button button-clear',
                    icon: false,
                    iconPos: '',
                    handler: () => {
                        editingGroup.value.name = '';
                        editingGroup.value.id = EMPTY_GUID;
                        editingGroup.value.imageGroupTypeId = imageGroupTypeId.value as string;
                        modalTitle.value = isImage.value ? language.value.newImageGroup : language.value.newIconGroup;
                        setOpen(true);
                    },
                    text: language.value.addGroup
                }]);
            }),
            presentOptions,
            isEditGroupOpen,
            setOpen,
            modalTitle,
            modalLabel,
            closeOutline,
            editingGroup,
            saveGroup,
            isImage
        }
    }
});
</script>

<style scoped>

</style>